import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { merge, Subject, throwError } from 'rxjs';

import { TUser } from 'src/app/project/modules/user/user.model';
import { EIntegrationStatus, TAllCustomFields, TCustomField } from '../custom-fields.model';

import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { CustomFieldsService, ECustomFieldsEventType } from '../custom-fields.service';

import { Router } from '@angular/router';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TNewCustomField } from 'src/app/project/data-providers/api-providers/workspace-api-provider/workspace-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EInstance } from 'src/app/project/shared/enums/instances';
import { EUserType } from '../../users/user-types-enum';
import { ECustomFieldType } from '../custom-field-types-enums';
import { getIsContainsSlash } from '../custom-fields-service.utils';
import { customFieldTypesData, TCustomFieldTypeData } from '../custom-fields.data';
import { TAddCustomFieldModalData, TSelectedCustomFields } from './add-custom-field.model';
import { AddCustomFieldService } from './add-custom-field.service';
import { ImportCustomFieldsService } from './import-custom-fields.service';
import { getIsBasicFieldName } from './utils/get-is-basic-field-name';

@Component({
  selector: 'app-add-custom-field-modal',
  templateUrl: './add-custom-field-modal.component.html',
  styleUrls: ['./add-custom-field-modal.component.scss'],
})
export class AddCustomFieldModalComponent implements OnInit, OnDestroy {
  customFields: TAllCustomFields = this.customFieldsService.getCustomFields();
  workspace: TWorkspace = null;
  workspaceId: string;
  workspaces: TWorkspacesById = null;
  importingCustomFields = false;
  processingImport = false;
  selectedCustomFields: TSelectedCustomFields = {};
  step: 'details' | 'select' = 'select';
  emptyListLabel = false;
  timelineExists = false;
  timelineEnabled = false;
  focused = false;
  sorted = this.customFieldsService.getAllSorted();
  maxListDepthExceeded = false;
  EUserRole = EUserRole;
  customFieldTypes = ECustomFieldType;

  totalSelectedFields: {
    number: number;
  } = {
    number: 0,
  };

  customFieldTypesData = customFieldTypesData;

  field: TCustomField = {
    type: null,
    label: '',
    currencyCode: 'EURO',
    subList: null,
    unit: null,
    decimalPlaces: null,
    showTotal: true,
    showHoursOnly: false,
    showCommas: false,
    id: null,
    maxListIndex: null,
    display: true,
    volyIntegrationActive: EIntegrationStatus.OFF,
    lockedTemplate: false,
    lockedValue: false,
    workspaceId: null,
  };

  processing = false;
  listCFError = false;
  integrationsVisible: boolean;

  user: TUser = {};

  private listDepth = 0;
  private maxListDepth: number;
  EIconPath = EIconPath;

  private readonly destroy$ = new Subject<void>();
  private modal: Modal<TAddCustomFieldModalData> = this.modalService.getModal();

  constructor(
    @Inject('HOSTNAME') private host: string,
    private customFieldsService: CustomFieldsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private userService: UserService,
    private translationPipe: TranslationPipe,
    private accountService: AccountService,
    private addCustomFieldService: AddCustomFieldService,
    private importCustomFieldsService: ImportCustomFieldsService,
  ) {
    this.user = this.userService.getUser();
  }

  ngOnInit(): void {
    this.workspaceId = this.modal.data.workspaceId;

    const customFields = this.customFieldsService.getCustomFields()[this.workspaceId];

    this.workspaces = this.workspaceService.getWorkspaces();
    this.workspace = this.workspaces[this.workspaceId];
    const account = this.accountService.getAccount(this.workspace.accountId);

    if (this.modal.data) {
      this.importingCustomFields = this.modal.data.importingCustomFields;
    }

    this.timelineEnabled = account.accountFeatures.timeline;

    Object.keys(customFields).forEach((customFieldId) => {
      if (customFields[customFieldId].type === ECustomFieldType.TIMELINE) {
        this.timelineExists = true;
      }
    });

    merge(
      this.customFieldsService.depthChange$.pipe(
        tap(() => {
          this.listDepth = this.customFieldsService.getCustomFieldListDepth(
            this.field.subList || [],
          );
        }),
      ),
      this.customFieldsService.maxListDepthChange$.pipe(
        tap((depth) => {
          this.maxListDepth = depth;
        }),
      ),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkDepthExceeded();
      });

    this.setVisibleIntegrationsField();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  save(): void {
    if (!this.modal.processing && !this.processing) {
      this.processing = true;
      this.field.label = this.field.label.trim();

      const workspaces = this.workspaceService.getWorkspaces();

      const fieldIndex = workspaces[this.workspaceId].customFields.findIndex(
        (fieldId) =>
          this.customFields[this.workspaceId][fieldId].label.toLowerCase() ===
          this.field.label.toLowerCase(),
      );

      if (fieldIndex > -1 || getIsBasicFieldName(this.field.label.toLowerCase())) {
        const promptText = this.translationPipe.transform('prompt_new_custom_field_taken');

        this.promptService.showWarning(promptText);
        this.processing = false;
      } else {
        if (this.field.type === ECustomFieldType.COST) {
          if (!this.field.currencyCode) {
            const promptText = this.translationPipe.transform('prompt_select_currency');

            this.promptService.showWarning(promptText);
            this.processing = false;
            return;
          }
        }

        if (this.field.type !== ECustomFieldType.LIST) {
          delete this.field.subList;
        } else {
          const listIsValid = this.validateList();

          if (!listIsValid) {
            this.processing = false;

            return;
          }
        }

        const newFieldData: TNewCustomField =
          this.customFieldsService.generateCustomFieldRequestData(this.field);

        this.addCustomFieldService
          .addCustomField(this.workspaceId, newFieldData)
          .pipe(
            finalize(() => {
              this.processing = false;
            }),
          )
          .subscribe();
      }
    }
  }

  uploadExcel(files: File[]): void {
    this.processing = true;
    const workspaceId = this.activeService.getActiveWorkspaceId();

    this.addCustomFieldService
      .addFieldFromExcel(workspaceId, files[0])
      .pipe(
        takeUntil(this.destroy$),
        catchError((err) => {
          let promptText = this.translationPipe.transform('prompt_field_create_error');

          if (err.status === EStatusCode.BAD_REQUEST) {
            promptText = this.translationPipe.transform('prompt_new_custom_field_taken');
          }

          this.promptService.showError(promptText);
          return throwError(err);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  setType(newType: TCustomFieldTypeData): void {
    this.step = 'details';
    this.field.type = newType.value;
  }

  toggleImport(importing: boolean): void {
    this.importingCustomFields = importing;
  }

  importCustomFields(): void {
    if (!this.processingImport) {
      this.processingImport = true;

      this.importCustomFieldsService
        .importCustomFields(
          this.selectedCustomFields,
          this.workspaceId,
          this.workspace,
          this.workspaces,
        )
        .pipe(
          tap(() => {
            this.hideModal();
          }),
          finalize(() => {
            this.processingImport = false;
          }),
        )
        .subscribe();
    }
  }

  back(): void {
    this.step = 'select';
  }

  updateListError(error: boolean): void {
    this.listCFError = error;
  }

  validateList(): boolean {
    let slashFound = false;

    if (this.field.subList) {
      slashFound = getIsContainsSlash(this.field.subList);
    }

    this.emptyListLabel = JSON.stringify(this.field).indexOf('"label":""') !== -1;

    if (this.emptyListLabel) {
      const promptText = this.translationPipe.transform('prompt_fill_all_warning');

      this.promptService.showWarning(promptText);
      this.customFieldsService.emit(ECustomFieldsEventType.SAVE_CUSTOM_FIELD_CLICKED);

      return false;
    }

    if (slashFound) {
      const promptText = this.translationPipe.transform(
        'prompt_invalid_character_slash_custom_field',
      );

      this.promptService.showWarning(promptText);

      return false;
    }

    return true;
  }

  private checkDepthExceeded(): void {
    this.maxListDepthExceeded = this.listDepth > this.maxListDepth;
  }

  private setVisibleIntegrationsField(): void {
    const user = this.userService.getUser();
    const isDeveloper = user.userType === EUserType.DEVELOPER || user.userType === EUserType.TESTER;
    const isASM = this.host === EInstance.ASM;

    this.integrationsVisible = isDeveloper || !isASM;
  }
}
