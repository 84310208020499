import { Component, OnDestroy, OnInit } from '@angular/core';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { SortingService } from '@core/helpers';
import { Observable, Subject, merge, of, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TItemToAdd, TSelectItem } from 'src/app/project/components/input/select/select.model';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { AttachmentsFilesService } from 'src/app/project/modules/points/attachments/attachments-files.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { UserService } from 'src/app/project/modules/user/user.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { ECustomFieldType } from '../../../custom-fields/custom-field-types-enums';
import { EIntegrationStatus } from '../../../custom-fields/custom-fields.model';
import { CustomFieldsService } from '../../../custom-fields/custom-fields.service';
import { TPoint } from '../../../points/points.model';
import { LONG_PROCESSING_TIME_MS } from '../site-options';
import { TFieldConflict } from '../site-options.model';
import { getFieldConflicts } from '../utils/getFieldConflicts';
import { ECopyMoveModalType } from './copy-move-modal-type..enum';
import {
  ECopyMoveItems,
  GET_DEFAULT_INCLUDE_ITEMS,
  TCopyMoveOptions,
  TIncludeItem,
} from './copy-move-options';
import { TPointsCopyMoveModalData } from './points-copy-move-modal.model';
import { PointsCopyMoveModalService } from './points-copy-move-modal.service';

@Component({
  selector: 'pp-points-copy-move-modal',
  templateUrl: './points-copy-move-modal.component.html',
  styleUrls: ['../pointCopyMove.scss'],
})
export class PointsCopyMoveModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly pointCopied$ = new Subject<void>();

  modal: Modal = this.modalService.getModal();
  modalData: TPointsCopyMoveModalData;

  modalType: ECopyMoveModalType = ECopyMoveModalType.COPY;
  ECopyMoveModalType = ECopyMoveModalType;
  ECustomFieldType = ECustomFieldType;
  EBasicField = EBasicField;
  EIconPath = EIconPath;

  processing = false;
  public accounts: TAccount[];
  public activeWorkspace: TWorkspace;
  public workspaces: TWorkspacesById;
  selectedWorkspaceId: string = null;
  selectedWorkspaceName: string = null;
  user: TUser;
  sizeError = false;
  sizeMB = 0;
  longProcessing = false;
  totalSize: number;
  firstStep = true;
  secondStep = false;
  integrationWarning: boolean;

  fieldConflicts: TFieldConflict[] = [];

  pointPinsToBeCentered = false;

  items: TSelectItem[] = [
    {
      label: '',
      options: [],
    },
  ];

  itemsToInclude: TIncludeItem[] = GET_DEFAULT_INCLUDE_ITEMS();

  readonly maxAttachmentSizeMB = 100;
  readonly maxAttachmentsSize = this.maxAttachmentSizeMB * 1000000;

  constructor(
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private attachmentsFilesService: AttachmentsFilesService,
    private sortingService: SortingService,
    private promptService: PromptService,
    private modalService: ModalService,
    private pointsCopyModalService: PointsCopyMoveModalService,
    private userService: UserService,
    private translationPipe: TranslationPipe,
    private customFieldsService: CustomFieldsService,
  ) {}

  ngOnInit() {
    this.accounts = this.accountService.getAccounts();
    this.workspaces = this.workspaceService.getWorkspaces();
    this.modalData = this.modal.data;
    this.activeWorkspace = this.workspaceService.getWorkspace(this.modalData.workspaceId);
    this.user = this.userService.getUser();
    this.modalType = this.modalData.modalType;
    this.integrationWarning = this.activeWorkspace.customFields.some((customFieldId) => {
      const customField = this.customFieldsService.getWorkspaceCustomField(
        this.activeWorkspace.workspaceId,
        customFieldId,
      );

      return customField.volyIntegrationActive !== EIntegrationStatus.OFF;
    });

    this.generateWorkspaceList();

    this.items.sort((a, b) => this.sortingService.naturalSort(a.label, b.label));

    this.items.forEach((account) => {
      account.options.sort((a, b) => this.sortingService.naturalSort(a.name, b.name));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private generateWorkspaceList(): void {
    this.accounts.forEach((account) => {
      const workspaces: TItemToAdd[] = [];

      account.workspaces.forEach((workspaceId) => {
        if (
          (this.workspaces[workspaceId].share.shareOption === EUserRole.OWNER ||
            this.user.isSuperUser ||
            this.workspaces[workspaceId].share.shareOption === EUserRole.SITE_ADMIN ||
            this.workspaces[workspaceId].share.shareOption === EUserRole.ACCOUNT_ADMIN ||
            this.workspaces[workspaceId].share.shareOption === EUserRole.NORMAL) &&
          (this.modalType === ECopyMoveModalType.COPY ||
            this.activeWorkspace.workspaceId !== workspaceId)
        ) {
          const itemToAdd: TItemToAdd = {
            name: this.workspaces[workspaceId].siteName,
            id: workspaceId,
            enabled: true,
            message: this.activeWorkspace.workspaceId === workspaceId ? 'current_site' : null,
          };

          workspaces.push(itemToAdd);
        } else if (this.activeWorkspace.workspaceId !== workspaceId) {
          const itemToAdd: TItemToAdd = {
            name: this.workspaces[workspaceId].siteName,
            id: workspaceId,
            enabled: false,
            disabledMessage: 'you_are_not_admin_normal',
          };

          workspaces.push(itemToAdd);
        } else {
          const itemToAdd: TItemToAdd = {
            name: this.workspaces[workspaceId].siteName,
            id: workspaceId,
            enabled: false,
            message: this.activeWorkspace.workspaceId === workspaceId ? 'current_site' : null,
            disabledMessage: 'cant_move_to_the_same_site',
          };

          workspaces.push(itemToAdd);
        }
      });

      if (workspaces.length > 0) {
        this.items.push({
          label: account.accountName,
          options: workspaces,
        });
      }
    });
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  selectWorkspace(selectedWorkspaceId: string, selectedWorkspaceName: string): void {
    this.selectedWorkspaceId = selectedWorkspaceId;
    this.selectedWorkspaceName = selectedWorkspaceName;
  }

  selectItem(item: { id: string; name: string }): void {
    this.selectedWorkspaceId = item.id;
    this.selectedWorkspaceName = item.name;
  }

  check(): void {
    this.processing = true;
    const sourceWorkspaceId = this.modalData.workspaceId;

    this.pointsCopyModalService
      .checkTargetSite(sourceWorkspaceId, this.selectedWorkspaceId)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const conflictsFound =
            response.missingCustomFields.COST ||
            response.missingCustomFields.DATE ||
            response.missingCustomFields.LIST ||
            response.missingCustomFields.TEXT ||
            response.missingCustomFields.RICHTEXT ||
            response.missingCustomFields.CHECKBOX ||
            response.missingCustomFields.TIME ||
            response.missingCustomFields.NUMBERS ||
            response.missingCustomFields.PERCENTAGE ||
            response.missingCustomFields.TIMELINE ||
            response.missingTags.length ||
            response.matchingSitePlans === false;

          this.firstStep = false;
          this.secondStep = true;

          if (response.matchingSitePlans === false) {
            this.pointPinsToBeCentered = true;
          }

          if (conflictsFound) {
            this.fieldConflicts.push(...getFieldConflicts(response));
          }
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform(
            this.modalType === ECopyMoveModalType.COPY
              ? 'prompt_copy_failed'
              : 'prompt_points_move_error',
          );

          if (error.status === EStatusCode.CONFLICT) {
            promptText = this.translationPipe.transform('prompt_request_in_progress');
          }

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  async confirm(): Promise<void> {
    const sourceWorkspaceId = this.modalData.workspaceId;
    let selectedPoints: TPoint[];
    let pointIds: string[];
    let numberOfPoints: number;
    this.processing = true;
    const options = this.getCopyMoveOptions();

    if (this.modalData.selectedPoint) {
      pointIds = [this.modalData.selectedPoint._id];
      numberOfPoints = 1;
    } else {
      selectedPoints = this.pointsService.getSelectedPoints();
      pointIds = selectedPoints.map((point) => point._id);
      numberOfPoints = pointIds.length;
    }

    this.attachmentsFilesService
      .fetchPointSizes(pointIds, sourceWorkspaceId, this.selectedWorkspaceId, options)
      .pipe(
        takeUntil(this.destroy$),
        tap((size) => {
          this.totalSize = size.documents + size.videos + size.images;

          this.itemsToInclude.forEach((item) => {
            switch (item.value) {
              case ECopyMoveItems.FILES:
                item.size = Math.round(+size.documents / 1000000);
                break;
              case ECopyMoveItems.VIDEOS:
                item.size = Math.round(+size.videos / 1000000);
                break;
              case ECopyMoveItems.PHOTOS:
                item.size = Math.round(+size.images / 1000000);
                break;
              default:
                break;
            }
          });

          if (this.totalSize < this.maxAttachmentsSize) {
            this.sendRequest(sourceWorkspaceId, numberOfPoints, pointIds, selectedPoints);
          } else {
            this.firstStep = false;
            this.secondStep = true;
            this.sizeMB = Math.round(+this.totalSize / 1000000);
            this.processing = false;
            this.sizeError = true;
          }
        }),
      )
      .subscribe();
  }

  private sendRequest(
    sourceWorkspaceId: string,
    numberOfPoints: number,
    pointIds: string[],
    selectedPoints: TPoint[],
  ): void {
    this.startLongProcessChecker();
    let request: Observable<string>;

    const options = this.getCopyMoveOptions();

    const pointsCopyParams = {
      sourceWorkspaceId,
      numberOfPoints,
      pointIds,
      selectedWorkspaceId: this.selectedWorkspaceId,
      options,
    };

    if (this.modalType === ECopyMoveModalType.COPY) {
      request = this.pointsCopyModalService.copyPoints(pointsCopyParams);
    } else {
      request = this.pointsCopyModalService.movePoints({
        ...pointsCopyParams,
        selectedPoints,
      });
    }

    request
      .pipe(
        tap(() => {
          this.hideModal();
        }),
        finalize(() => {
          this.processing = false;
          this.stopLongProcessChecker();
        }),
      )
      .subscribe();
  }

  private getCopyMoveOptions(): TCopyMoveOptions {
    const options: TCopyMoveOptions = {
      activities: true,
      comments: true,
      images: true,
      videos: true,
      documents: true,
      description: true,
    };

    this.itemsToInclude.forEach((item) => {
      options[item.value] = item.selected;
    });

    return options;
  }

  private startLongProcessChecker(): void {
    timer(LONG_PROCESSING_TIME_MS)
      .pipe(
        takeUntil(merge(this.pointCopied$, this.destroy$)),
        tap(() => {
          this.longProcessing = true;
        }),
      )
      .subscribe();
  }

  private stopLongProcessChecker(): void {
    this.pointCopied$.next();
    this.longProcessing = false;
  }

  updateItemsToInclude(item: TIncludeItem): void {
    item.selected = !item.selected;

    this.totalSize = this.itemsToInclude.reduce(
      (acc, itemToInclude) => acc + (itemToInclude.selected ? itemToInclude.size : 0),
      0,
    );
  }
}
