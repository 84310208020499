export type TGoogleTagEventsUnion =
  | EGoogleEventSidePanel
  | EGoogleEventSiteList
  | EGoogleEventUserDropdown
  | EGoogleEventDashboard
  | EGoogleEventSite
  | EGoogleEventSettings
  | EGoogleEventUserAccess
  | EGoogleEventUserSettings
  | EGoogleEventNotifications
  | EGoogleEventNewSite
  | EGoogleEventReminders
  | EGoogleEventSavedViews
  | EGoogleEventGrouping
  | EGoogleEventSorting
  | EGoogleEventFiltering
  | EGoogleEventFleetManagement;

export enum EGoogleEventLabel {
  CF = 'CF',
  COMMENT = 'comment',
  TIMELINE = 'timeline',
  SITE_PLAN = 'site_plan',
  EXPORT = 'export',
  SAVED_VIEW = 'saved_view',
  GROUPING = 'grouping',
  FILTERING = 'filtering',
  FLEET_MANAGEMENT = 'fleet_management',
}

export type TGoogleEventParams = {
  event_category: EGoogleEventCategory;
  event_label?: string;
  event_value?: number;
  event_details?: string;
  event_type?: string;
  amount?: number;
};

export type TGoogleConfigDetails = {
  path: string;
  version: string;
  shareOption: string;
  industryType: string;
  accountType: string;
};

export enum EGoogleEventCategory {
  SIDE_PANEL = 'Side Panel',
  SITE_LIST = 'Accounts & Sites',
  USER_DROPDOWN = 'User Dropdown',
  DASHBOARD = 'Dashboard',
  SITE = 'Site',
  SETTINGS = 'Settings',
  USER_ACCESS = 'User Access',
  PROFILE_SETTINGS = 'Profile Settings',
  NOTIFICATIONS = 'In-App Notifications',
  ACCOUNT = 'Account,',
  CREATE_SITE = 'New Site Page',
  REMINDERS = 'Reminders',
  SAVED_VIEWS = 'Saved Views',
  GROUPING = 'Grouping',
  SORTING = 'Sorting',
  FILTERING = 'Filtering',
  FLEET_MANAGEMENT = 'Fleet Management',
}

export enum EGoogleEventSidePanel {
  SIDE_PANEL_PANEL_EXPAND = 'Side Panel - Panel Expand',
  SIDE_PANEL_DASHBOARD = 'Side Panel - Dashboard',
  SIDE_PANEL_OVERVIEW = 'Side Panel - Overview Table',
  SIDE_PANEL_TIMELINE = 'Side Panel - Timeline',
  SIDE_PANEL_USER_DROPDOWN = 'Side Panel - User Dropdown Menu',
  SIDE_PANEL_NOTIFICATIONS = 'Side Panel - In-App Notifications',
  SIDE_PANEL_NEW_SITE = 'Side Panel - New Site',
  SIDE_PANEL_REMINDERS = 'Side Panel - Reminders',
}

export enum EGoogleEventSiteList {
  SITE_LIST_SITE_SETTINGS = 'Accounts & Sites - Site Settings', // selected option as label
  SITE_LIST_ACCOUNT_SETTINGS = 'Accounts & Sites - Account Settings',
}

export enum EGoogleEventUserDropdown {
  USER_DROPDOWN__ACCOUNT_SETTINGS = 'User Dropdown - Account Settings',
  USER_DROPDOWN__PROFILE_SETTINGS = 'User Dropdown - Profile Settings',
  USER_DROPDOWN__CONTACT = 'User Dropdown - Contact',
  USER_DROPDOWN__HELP = 'User Dropdown - Help Centre',
  USER_DROPDOWN__CHANGELOG = "User Dropdown - What's new",
  USER_DROPDOWN__COOKIES = 'User Dropdown - Cookie Preferences',
  USER_DROPDOWN__UPGRADE = 'User Dropdown - Upgrade',
  USER_DROPDOWN_HIRING = 'User Dropdown - Hiring',
}

export enum EGoogleEventDashboard {
  DASHBOARD__NEW_BUTTON = 'Dashboard - New Dashlet Button',
  DASHBOARD__DASHLET__ADD = 'Dashboard - Add Dashlet', // label with dashlet type
  DASHBOARD__DASHLET__FLIP = 'Dashboard - Flip Dashlet', // label with dashlet type
  DASHBOARD__DASHLET__DELETE = 'Dashboard - Delete Dashlet', // label with dashlet type
  DASHBOARD__DASHLET__CHANGE_POSITION = 'Dashboard - Change Dashlet Position',
  DASHBOARD__SET_DEFAULT = 'Dashboard - Set Default Layout',
  DASHBOARD__ACTIVITY_CLICKED = 'Dashboard - Activity Feed Clicked',
  DASHBOARD__EXPORT_BUTTON = 'Dashboard - Export Button Clicked',
}

export enum EGoogleEventSite {
  SITE__FILTER__CLEAR_ALL = 'Site filters - Clear All',
  SITE__EXPORT = 'Site Export',
  SITE__EXPORT__IMAGES = 'Site Export - Images', // (Images size as label)
  SITE__COLUMNS__HIDE = 'Site - Hide Columns', // (column type as label)
  SITE__COLUMNS__SHOW = 'Site - Show Columns', // (column type as label)
  SITE__COLUMNS__MOVE = 'Site - Move Columns', // (column type as label)
  SITE__COLUMNS__RESET = 'Site - Reset Table',
  SITE__SEARCH__KEYWORDS = 'Site - Search By Keyword', // (triggered on search action)
  SITE__BULK_CHANGES__EDIT = 'Site - Bulk Changes - Edit', // (value: number of points, label: chosen option)
  SITE__BULK_CHANGES__COPY = 'Site - Bulk Changes - Copy', // (value: number of points)
  SITE__BULK_CHANGES__MOVE = 'Site - Bulk Changes - Move', // (value: number of points)
  SITE__BULK_CHANGES__DELETE = 'Site - Bulk Changes - Delete', // (value: number of points)
  SITE__BULK_CHANGES__EXPORT = 'Site - Bulk Changes - Export', // (value: number of points)
  SITE__POINT__PREVIOUS = 'Site - Previous Point',
  SITE__POINT__NEXT = 'Site - Next Point',
  SITE__POINT__EXPAND = 'Site - Expand Point',
  SITE__POINT__COLLAPSE = 'Site - Collapse Point',
  SITE__POINT__EXPORT = 'Site - Export Point',
  SITE__POINT__DELETE = 'Site - Delete Point',
  SITE__POINT__COMPLETED = 'Site - Point Completed',
  SITE__POINT__PIN_CHANGE = 'Site - Change Pin Location',
  SITE__POINT__ATTACHMENT_DOWNLOAD = 'Site - Download Point Attachment',
  SITE__POINT__ATTACHMENT_DELETE = 'Site - Point Attachment Delete',
  SITE__POINT__ATTACHMENT_RENAME = 'Site - Point Attachment Rename',
  SITE__POINT__ATTACHMENT_UPLOAD = 'Site - Point Attachment Upload',
  SITE__POINT__COMMENTS_ONLY = 'Site - Show Only Comments',
  SITE__POINT__COMMENT_ON_ENTER = 'Site - Press Enter to send Comment',
  SITE__POINT__TIMELINE_ALL = 'Site – Show All Timeline',
  SITE__POINT__COMMENT = 'Site - Add Comment',
  SITE__GALLERY__ANNOTATE = 'Site - Gallery Annotate',
  SITE__GALLERY__ROTATE = 'Site - Gallery Rotate',
  SITE__GALLERY__DOWNLOAD = 'Site - Gallery Download',
  SITE__GALLERY__DELETE = 'Site - Gallery Delete',
  SITE__GALLERY__ZOOM = 'Site - Gallery Zoom',
  SITE__GALLERY__TOOL = 'Site - Gallery Annotate Tool',
  SITE__GALLERY__COLORS = 'Site - Gallery Annotate Colors',
  SITE__GALLERY__OBJECT__DELETE = 'Site - Gallery Annotate Object - Delete',
  SITE__GALLERY__OBJECT__MOVE = 'Site - Gallery Annotate Object - Move',
  SITE__GALLERY__OBJECT__CHANGE_COLOR = 'Site - Gallery Annotate Object - Change Color',
  SITE_GROUP_MOVE = 'Site - Move Group',
}

export enum EGoogleEventSettings {
  SETTINGS__CF__NEW = 'Settings - Add Custom Field',
  SETTINGS__CF__EDIT = 'Settings - Edit Custom Field',
  SETTINGS__CF__IMPORT = 'Settings - Import Custom Field',
  SETTINGS__TAGS__NEW = 'Settings - Create Tag',
  SETTINGS__TAGS__IMPORT = 'Settings - Import Tags',
  SETTINGS__TAGS__DELETE = 'Settings - Delete Tag',
  SETTINGS__USER__NEW = 'Settings - Add User',
  SETTINGS__USER__EDIT = 'Settings - Edit User',
  SETTINGS__USER__RESEND_INVITATION = 'Settings - Resend User Invite',
  SETTINGS__USER__DELETE = 'Settings - Delete User',
  SETTINGS__ACCOUNT__USER__DELETE = 'Settings - Delete User From Account',
  SETTINGS__SITE_PLAN__UPDATE = 'Settings - Update Site Plan',
  SETTINGS__PDF_LOGO__UPDATE = 'Settings - Update PDF Logo',
  SETTINGS__USER__EXCEL = 'Settings - import users from Excel',
}

export enum EGoogleEventUserAccess {
  USER_ACCESS__IMPORT = 'User Access - Import Users',
}

export enum EGoogleEventUserSettings {
  PROFILE_SETTINGS__AVATAR = 'Profile Settings - Change Avatar',
  PROFILE_SETTINGS__AVATAR_DELETE = 'Profile Settings - Delete Avatar',
  PROFILE_SETTINGS__PASSWORD = 'Profile Settings - Change Password',
  PROFILE_SETTINGS__USERNAME = 'Profile Settings - Change Username',
  PROFILE_SETTINGS__ZOOM_SPEED = 'Profile Settings - Change Site Plan Zoom Speed',
  PROFILE_SETTINGS__NOTIFICATIONS = 'Profile Settings - Change Email Notifications', // Frequency as parameter
  PROFILE_SETTINGS__DATE_FORMAT = 'Profile Settings - Change Date Format', // format as parameter
  SUBSCRIPTION_CONTACT = 'Profile Settings - Subscription Contact',
}

export enum EGoogleEventNotifications {
  NOTIFICATIONS__OPEN = 'In-App Notifications - Open',
  NOTIFICATIONS__MARK_AS_READ = 'In-App Notifications - Mark As Read',
  NOTIFICATIONS__MARK_AS_UNREAD = 'In-App Notifications - Mark As Unread',
  NOTIFICATIONS__CLEAR = 'In-App Notifications - Clear',
  NOTIFICATIONS__MARK_ALL_AS_READ = 'In-App Notifications - Mark All As Read',
  NOTIFICATIONS_MODE = 'In-App Notifications - Switch Mode',
  NOTIFICATIONS_RESPOND = 'In-App Notifications - Respond',
  NOTIFICATIONS_FILTER = 'In-App Notifications - Filter',
}

export enum EGoogleEventNewSite {
  CREATE_SITE_CREATE_SITE = 'New Site Page - Create Site',
  CREATE_SITE_CONTACT = 'New Site Page - Contact Us',
  CREATE_SITE_SUBSCRIPTION_CONTACT = 'New Site Page - Contact Us from Subscription',
  CREATE_SITE_SUBSCRIPTION_INCREASE_LIMIT = 'New Site Page - Increase Limit',
}

export enum EGoogleEventReminders {
  REMINDERS_OPEN_FROM_ALERT = 'Reminders - Open panel from alert',
  REMINDERS_DELETE = 'Reminders - delete reminder',
}

export enum EGoogleEventSavedViews {
  CREATE_SAVED_VIEW = 'Saved Views - Create saved view',
  DELETE_SAVED_VIEW = 'Saved Views - Delete saved view',
  RENAME_SAVED_VIEW = 'Saved Views - Rename saved view',
  SET_VIEW_AS_DEFAULT = 'Saved Views - Set view as default',
  DUPLICATE_VIEW = 'Saved Views - Duplicate view',
  DUPLICATE_TO_SHARED_VIEW = 'Saved Views - Duplicate to shared view',
  DUPLICATE_TO_PERSONAL_VIEW = 'Saved Views - Duplicate to personal view',
  CLEAR_DEFAULT = 'Saved Views - Clear default view',
}

export enum EGoogleEventGrouping {
  ADD_GROUPING = 'Grouping - create group',
  DELETE_GROUPING = 'Grouping - delete group',
  UPDATE_GROUP = 'Grouping - update group',
}

export enum EGoogleEventSorting {
  ADD_SORTING = 'Sorting - add sorting',
  DELETE_SORTING = 'Sorting - delete sorting',
  UPDATE_SORTING = 'Sorting - update sorting',
}

export enum EGoogleEventFiltering {
  ADD_ADVANCED_FILTER = 'Filtering - add advanced filter',
  DELETE_ADVANCED_FILTER = 'Filtering - delete advanced filter',
  UPDATE_ADVANCED_FILTER = 'Filtering - update advanced filter',
  EDIT_BASIC_FILTER = 'Filtering - edit basic filter',
}

export enum EGoogleEventFleetManagement {
  OPEN_OVERVIEW_FROM_ASSET = 'Fleet Management - Open Overview from Asset',
  ASSET_LABEL_HOVER = 'Fleet Management - Asset Label Hover',
  ASSET_LABEL_CLICK = 'Fleet Management - Asset Label Click',
  ASSET_DETAILS_CLICK = 'Fleet Management - Asset Details Click',
  ASSET_LABEL_SCROLL = 'Fleet Management - Asset Label Scroll',
  ASSET_GO_TO_EDIT = 'Fleet Management - Asset Go To Edit Page',
  ASSET_DELETE = 'Fleet Management - Asset Delete',
  GO_TO_NEW_ASSET_PAGE = 'Fleet Management - Asset Go To New Asset Page',
  GO_TO_CREATE_NEW_FLEET_PAGE = 'Fleet Management - Go To Create New Fleet Page',
  CREATE_NEW_FLEET = 'Fleet Management - Create New Fleet',
  ASSET_CREATE_NEW = 'Fleet Management - Asset Create New',
  FLEET_DELETE = 'Fleet Management - Fleet Delete',
  ASSET_EDIT = 'Fleet Management - Asset Edit',
  FLEET_SELECT = 'Fleet Management - Fleet Select',
  FLEET_SEARCH = 'Fleet Management - Fleet Search',
  FLEET_SHARE_MODAL = 'Fleet Management - Go To Fleet Share Modal',
  FLEET_SHARE = 'Fleet Management - Fleet Share',
  FLEET_SHARE_DELETE = 'Fleet Management - Revoke View Access',
  FLEET_EDIT = 'Fleet Management - Fleet Edit',
  ASSET_DASHLET_ADD = 'Fleet Management - Add Asset Dashlet',
  ASSET_DASHLET_REMOVE = 'Fleet Management - Remove Asset Dashlet',
}
