import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { CurrencyPickerModule } from '../../components/currency-picker/currency-picker.module';
import { DoubleSelectModule } from '../../components/double-select/double-select.module';
import { GridTileModule } from '../../components/grid-tile/grid-tile.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { DatepickerModule } from '../../components/input/datepicker/datepicker.module';
import { RadioModule } from '../../components/input/radio/radio.module';
import { RichTextModule } from '../../components/input/rich-text/rich-text.module';
import { UploadModule } from '../../components/input/upload/upload.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SaveIndicatorModule } from '../../components/save-indicator/save-indicator.module';
import { SearchBoxModule } from '../../components/search-box/search-box.module';
import { SiteListSelectModule } from '../../components/site-list-select/site-list-select.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { AddCustomFieldImportSelectAllComponent } from './add-custom-field-modal/add-custom-field-modal-import/add-custom-field-import-select-all/add-custom-field-import-select-all.component';
import { AddCustomFieldImportTableHeaderComponent } from './add-custom-field-modal/add-custom-field-modal-import/add-custom-field-import-table-header/add-custom-field-import-table-header.component';
import { AddCustomFieldModalImportFieldComponent } from './add-custom-field-modal/add-custom-field-modal-import/add-custom-field-modal-import-field/add-custom-field-modal-import-field.component';
import { AddCustomFieldModalImportComponent } from './add-custom-field-modal/add-custom-field-modal-import/add-custom-field-modal-import.component';
import { AddCustomFieldModalComponent } from './add-custom-field-modal/add-custom-field-modal.component';
import { BulkCustomFieldsModalComponent } from './bulk-custom-fields-modal/bulk-custom-fields-modal.component';
import { BulkFieldCheckboxComponent } from './bulk-custom-fields-modal/bulk-field-checkbox/bulk-field-checkbox.component';
import { BulkFieldCostComponent } from './bulk-custom-fields-modal/bulk-field-cost/bulk-field-cost.component';
import { BulkFieldDateComponent } from './bulk-custom-fields-modal/bulk-field-date/bulk-field-date.component';
import { BulkFieldListComponent } from './bulk-custom-fields-modal/bulk-field-list/bulk-field-list.component';
import { BulkFieldNumbersComponent } from './bulk-custom-fields-modal/bulk-field-numbers/bulk-field-numbers.component';
import { BulkFieldPercentageComponent } from './bulk-custom-fields-modal/bulk-field-percentage/bulk-field-percentage.component';
import { BulkFieldRichTextComponent } from './bulk-custom-fields-modal/bulk-field-rich-text/bulk-field-rich-text.component';
import { BulkFieldTextComponent } from './bulk-custom-fields-modal/bulk-field-text/bulk-field-text.component';
import { BulkFieldTimeComponent } from './bulk-custom-fields-modal/bulk-field-time/bulk-field-time.component';
import { BulkFieldTimelineComponent } from './bulk-custom-fields-modal/bulk-field-timeline/bulk-field-timeline.component';
import { CustomFieldCostComponent } from './custom-field-cost/custom-field-cost.component';
import { CustomFieldListSelectDropdownComponent } from './custom-field-list-select-dropdown/custom-field-list-select-dropdown.component';
import { CustomFieldListSelectComponent } from './custom-field-list-select/custom-field-list-select.component';
import { CustomFieldListComponent } from './custom-field-list/custom-field-list.component';
import { CustomFieldNumbersComponent } from './custom-field-numbers/custom-field-numbers.component';
import { CustomFieldPercentageComponent } from './custom-field-percentage/custom-field-percentage.component';
import { CustomFieldRichTextComponent } from './custom-field-rich-text/custom-field-rich-text.component';
import { CustomFieldTimeComponent } from './custom-field-time/custom-field-time.component';
import { CustomFieldTimelineComponent } from './custom-field-timeline/custom-field-timeline.component';
import { CustomFieldsCheckboxComponent } from './custom-fields-checkbox/custom-fields-checkbox.component';
import { CustomFieldsService } from './custom-fields.service';
import { DeleteCustomFieldModalComponent } from './delete-custom-field-modal/delete-custom-field-modal.component';
import { EditCustomFieldDetailsComponent } from './edit-custom-field-details/edit-custom-field-details.component';
import { EditCustomFieldModalComponent } from './edit-custom-field-modal/edit-custom-field-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    SiteListSelectModule,
    DoubleSelectModule,
    DatepickerModule,
    RadioModule,
    UploadModule,
    CheckboxModule,
    RichTextModule,
    GridTileModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
    ModalModule,
    SearchBoxModule,
    IconModule,
    SaveIndicatorModule,
    CurrencyPickerModule,
  ],
  providers: [CustomFieldsService],
  exports: [
    EditCustomFieldModalComponent,
    DeleteCustomFieldModalComponent,
    AddCustomFieldModalComponent,
    CustomFieldListComponent,
    CustomFieldListSelectComponent,
    CustomFieldCostComponent,
  ],
  declarations: [
    EditCustomFieldModalComponent,
    DeleteCustomFieldModalComponent,
    AddCustomFieldModalComponent,
    CustomFieldListComponent,
    CustomFieldListSelectComponent,
    BulkCustomFieldsModalComponent,
    CustomFieldListSelectDropdownComponent,
    CustomFieldCostComponent,
    AddCustomFieldModalImportComponent,
    AddCustomFieldModalImportFieldComponent,
    CustomFieldPercentageComponent,
    CustomFieldsCheckboxComponent,
    CustomFieldRichTextComponent,
    CustomFieldNumbersComponent,
    CustomFieldTimeComponent,
    CustomFieldTimelineComponent,
    AddCustomFieldImportSelectAllComponent,
    AddCustomFieldImportTableHeaderComponent,
    BulkFieldTextComponent,
    BulkFieldDateComponent,
    BulkFieldListComponent,
    BulkFieldCostComponent,
    BulkFieldPercentageComponent,
    BulkFieldTimelineComponent,
    BulkFieldTimeComponent,
    BulkFieldNumbersComponent,
    BulkFieldRichTextComponent,
    BulkFieldCheckboxComponent,
    EditCustomFieldDetailsComponent,
  ],
})
export class CustomFieldsModule {}
