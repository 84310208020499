import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { AssetService } from 'src/app/project/modules/fleet-management/asset-service/asset.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TDashletBudgetItem } from '../new-dashlet-modal-cost-fields.consts';
import { NewDashletModalCostFieldsService } from '../new-dashlet-modal-cost-fields.service';

@Component({
  selector: 'pp-new-dashlet-modal-cost-field',
  templateUrl: './new-dashlet-modal-cost-field.component.html',
  styleUrl: './new-dashlet-modal-cost-field.component.scss',
})
export class NewDashletModalCostFieldComponent implements OnChanges, OnInit, OnDestroy {
  @Input() ppAssetId: string;
  @Input() ppData: TDashletBudgetItem;
  @Input() ppItemRows: TDashletBudgetItem[];
  @Output() ppSiteRowChange: EventEmitter<TDashletBudgetItem> =
    new EventEmitter<TDashletBudgetItem>();
  @Output() ppDeleteRow = new EventEmitter<void>();

  private readonly destroy$ = new Subject<void>();

  EIconPath = EIconPath;
  assetSites: TSelectOption[] = [];
  workspaceCustomFields: TSelectOption[] = [];
  assetSiteIndex: number = -1;
  showingError: boolean = false;
  emptyCostFieldError: boolean = false;
  emptyBudgetFieldError: boolean = false;

  workspaceCostIndex: number = -1;
  workspaceBudgetIndex: number = -1;

  filteredAssetSites: TSelectOption[] = [];
  filteredCostFields: TSelectOption[] = [];
  filteredBudgetFields: TSelectOption[] = [];

  constructor(
    private assetService: AssetService,
    private customFieldsService: CustomFieldsService,
    private newDashletModalCostFieldsService: NewDashletModalCostFieldsService,
  ) {
    this.newDashletModalCostFieldsService.sitesChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterAssetSites();
      });
  }

  ngOnChanges(): void {
    const asset = this.assetService.getAsset(this.ppAssetId);

    this.assetSites = asset.sites.map((site) => ({
      label: site.siteName,
      value: site.workspaceId,
    }));

    this.filterAssetSites();
    this.filterCostFields();
    this.filterBudgetFields();

    this.assetSiteIndex = this.assetSites.findIndex(
      (assetSite) => assetSite.value === this.ppData.workspaceId,
    );

    if (this.assetSiteIndex !== -1) {
      this.updateCustomFields();

      this.workspaceBudgetIndex = this.workspaceCustomFields.findIndex(
        (workspaceCustomField) => workspaceCustomField.value === this.ppData.budgetFieldId,
      );
      this.workspaceCostIndex = this.workspaceCustomFields.findIndex(
        (workspaceCustomField) => workspaceCustomField.value === this.ppData.costFieldId,
      );

      this.updateCustomFields();
    }

    this.checkError();
  }

  ngOnInit() {
    if (this.ppData.budgetFieldId) {
      const budgetCustomField = this.customFieldsService.getWorkspaceCustomField(
        this.ppData.workspaceId,
        this.ppData.budgetFieldId,
      );

      this.emptyBudgetFieldError = !budgetCustomField;
    }

    if (this.ppData.costFieldId) {
      const costCustomField = this.customFieldsService.getWorkspaceCustomField(
        this.ppData.workspaceId,
        this.ppData.costFieldId,
      );

      this.emptyCostFieldError = !costCustomField;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectSite(site: TSelectOption): void {
    this.assetSiteIndex = this.assetSites.findIndex((assetSite) => assetSite.value === site.value);

    this.ppData.workspaceId = site.value;
    this.ppData.costFieldId = null;
    this.ppData.budgetFieldId = null;
    this.workspaceCostIndex = -1;
    this.workspaceBudgetIndex = -1;
    this.showingError = false;
    this.updateCustomFields();

    this.updateData();
    this.filterAssetSites();
  }

  selectCostField(costField: TSelectOption): void {
    this.workspaceCostIndex = this.workspaceCustomFields.findIndex(
      (workspaceCustomField) => workspaceCustomField.value === costField.value,
    );

    this.ppData.costFieldId = costField.value;

    this.updateData();
    this.filterBudgetFields();
    this.checkError();

    this.emptyCostFieldError = false;
  }

  selectBudgetField(budgetField: TSelectOption): void {
    this.workspaceBudgetIndex = this.workspaceCustomFields.findIndex(
      (workspaceCustomField) => workspaceCustomField.value === budgetField.value,
    );

    this.ppData.budgetFieldId = budgetField.value;

    this.updateData();
    this.filterCostFields();
    this.checkError();

    this.emptyBudgetFieldError = false;
  }

  deleteRow(): void {
    this.ppDeleteRow.emit();
  }

  private updateCustomFields(): void {
    const customFields = this.customFieldsService.getWorkspaceCustomFields(this.ppData.workspaceId);
    const workspaceCustomFields: TSelectOption[] = [];

    Object.keys(customFields).map((customFieldId) => {
      const customField = customFields[customFieldId];

      if (customField.type === ECustomFieldType.COST) {
        workspaceCustomFields.push({
          label: customField.currencyCode + ' ' + customField.label,
          value: customFieldId,
        });
      }
    });

    this.workspaceCustomFields = workspaceCustomFields;

    this.filterBudgetFields();
    this.filterCostFields();
  }

  private updateData(): void {
    this.ppSiteRowChange.emit(this.ppData);
  }

  private filterAssetSites(): void {
    const workspaceId = this.ppData.workspaceId;

    this.filteredAssetSites = this.assetSites.filter((assetSite) => {
      return (
        assetSite.value === workspaceId ||
        !this.ppItemRows.find((itemRow) => itemRow.workspaceId === assetSite.value)
      );
    });
  }

  private filterCostFields(): void {
    const budgetFieldId = this.ppData.budgetFieldId;

    if (!budgetFieldId) {
      this.filteredCostFields = this.workspaceCustomFields;
    } else {
      this.filteredCostFields = this.workspaceCustomFields.filter((workspaceCustomField) => {
        return workspaceCustomField.value !== budgetFieldId;
      });
    }
  }

  private filterBudgetFields(): void {
    const costFieldId = this.ppData.costFieldId;

    if (!costFieldId) {
      this.filteredBudgetFields = this.workspaceCustomFields;
    } else {
      this.filteredBudgetFields = this.workspaceCustomFields.filter((workspaceCustomField) => {
        return workspaceCustomField.value !== costFieldId;
      });
    }
  }

  private checkError(): void {
    if (this.workspaceCostIndex === -1 || this.workspaceBudgetIndex === -1) {
      this.showingError = false;
      return;
    }

    const costCustomField = this.customFieldsService.getWorkspaceCustomField(
      this.ppData.workspaceId,
      this.ppData.costFieldId,
    );

    const budgetCustomField = this.customFieldsService.getWorkspaceCustomField(
      this.ppData.workspaceId,
      this.ppData.budgetFieldId,
    );

    this.showingError = costCustomField.currencyCode !== budgetCustomField.currencyCode;
  }
}
