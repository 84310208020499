import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pp-site-settings-integrations-get-started',
  templateUrl: './site-settings-integrations-get-started.component.html',
  styleUrls: ['./site-settings-integrations-get-started.component.scss'],
})
export class SiteSettingsIntegrationsGetStartedComponent {
  @Input() ppDisabled: boolean;
  @Output() ppEnableIntegration = new EventEmitter<void>();

  onIntegrationStart(): void {
    this.ppEnableIntegration.emit();
  }
}
