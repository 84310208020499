import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, forkJoin, throwError } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { CommentDeleteDropdownComponent } from './comment-delete-dropdown/comment-delete-dropdown.component';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { SiteFilterDataService } from 'src/app/project/modules/filters/site-filter-data-service/site-filter-data.service';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { PointActivityService, TActivityData, TCommentsData } from './point-activity.service';

import { HttpErrorResponse } from '@angular/common/http';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ReactionsService } from 'src/app/project/services/reactions.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { PointActivityCommentsService } from './point-activity-comments.service';

@Component({
  selector: 'pp-point-activity',
  templateUrl: './point-activity.component.html',
  styleUrls: ['./point-activity.component.scss'],
})
export class PointActivityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppCommentsEnabled = false;
  @Input() ppDetailsFetched = false;
  @Input() ppAttachmentUploading = false;

  @Output() ppScrollToComment = new EventEmitter();

  private readonly destroy$ = new Subject<void>();

  private workspaces$: Observable<TWorkspacesById>;
  workspace: TWorkspace;

  activityData: TActivityData;
  comments: TCommentsData;
  EUserRole = EUserRole;
  EIconPath = EIconPath;

  public processing: boolean;
  commentsOnly: boolean;
  isAvatarDefault = false;
  timelineVisible = false;
  commentsVisible = false;
  private hasAccessToTimeline = false;
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  public date = new Date().getTime();
  fetchedActivitiesVersion = 0;

  user: TUser = {};

  constructor(
    private store: Store<{ workspaces: TWorkspacesById }>,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private pointActivityCommentsService: PointActivityCommentsService,
    private permissionsService: PermissionsService,
    private dropdownService: DropdownService,
    private userService: UserService,
    private translationPipe: TranslationPipe,
    private reactionsService: ReactionsService,
    private siteFilterDataService: SiteFilterDataService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit() {
    this.activityData = this.pointActivityService.getActivity();
    this.comments = this.pointActivityCommentsService.getComments();

    this.processing = false;
    this.commentsOnly = false;

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.checkPermissions(workspaces);

      if (!this.ppDetailsFetched) {
        return;
      }

      this.initActivities();
    });

    this.user = this.userService.getUser();
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    const isOnlyChange = Object.keys(changes).length === 1;
    const detailsFetchedChange = changes.ppDetailsFetched;
    const attachmentUploadingChange = changes.ppAttachmentUploading;

    if (detailsFetchedChange && isOnlyChange) {
      this.initActivities();
    }

    if (!(attachmentUploadingChange && isOnlyChange)) {
      if (changes.ppPointId || changes.ppWorkspaceId) {
        this.pointActivityService.clearActivity();
      }

      if (this.ppDetailsFetched) {
        this.startActivities();
      }
    }
  }

  ngOnDestroy() {
    this.pointActivityService.clearActivity();
    this.destroy$.next();
  }

  fetchActivities(pageNumber?: number): void {
    this.processing = true;

    forkJoin([
      this.reactionsService.fetchReactions(this.ppPointId),
      this.pointActivityService.fetchActivity(this.ppWorkspaceId, this.ppPointId, {
        pageNumber,
      }),
    ])
      .pipe(
        takeUntil(this.destroy$),
        tap(([reactions, activities]) => {
          this.fetchedActivitiesVersion++;

          this.activityData = activities;

          if (this.activityData.pageNumber === 1) {
            this.ppScrollToComment.emit();
          }
        }),
        catchError((error) => {
          this.handleError(error);

          return throwError(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  handleError(error: HttpErrorResponse): void {
    if (error.status === EStatusCode.FORBIDDEN) {
      const promptText = this.translationPipe.transform('prompt_access_error');

      this.promptService.showError(promptText, { duration: 15 });
    } else if (error.status === EStatusCode.UNAUTHORIZED) {
      const promptText = this.translationPipe.transform('prompt_request_logged_out');

      this.promptService.showError(promptText, { duration: 15 });
    } else {
      const promptText = this.translationPipe.transform('prompt_activities_error');

      this.promptService.showError(promptText);
    }
  }

  loadMore(): void {
    const nextPageNumber = (this.activityData.pageNumber += 1);

    if (nextPageNumber <= this.activityData.pagination.totalPages) {
      this.fetchActivities(nextPageNumber);
    }
  }

  showAllActivities(): void {
    this.commentsOnly = false;

    logEventInGTAG(EGoogleEventSite.SITE__POINT__TIMELINE_ALL, {
      event_category: EGoogleEventCategory.SITE,
    });
  }

  showCommentsOnly(): void {
    this.commentsOnly = true;
    this.processing = true;

    logEventInGTAG(EGoogleEventSite.SITE__POINT__COMMENTS_ONLY, {
      event_category: EGoogleEventCategory.SITE,
    });

    this.pointActivityCommentsService
      .fetchComments(this.ppPointId)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.reactionsService.fetchReactions(this.ppPointId)),
        tap(() => {
          this.ppScrollToComment.emit();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  showDefaultAvatar(): void {
    this.isAvatarDefault = true;
  }

  showDeleteCommentDropdown(commentId: string, buttonId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData({
        commentId,
      });

      this.dropdownService.showDropdown(buttonId, CommentDeleteDropdownComponent, {
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false,
            },
          },
        ],
      });
    }
  }

  onTimelineHeadingRendered(element: HTMLElement): void {
    this.pointActivityService.setPointTimelineHeading(element);
  }

  private checkPermissions(workspaces: TWorkspacesById): void {
    this.workspace = workspaces[this.ppWorkspaceId];
    this.hasAccessToTimeline = true;

    const timelinePermissions = this.permissionsService.areActivitiesEnabled(this.ppWorkspaceId);
    const tagPermissions = this.permissionsService.getTagPermissions(this.ppWorkspaceId);
    const commentPermissions = this.permissionsService.getCommentPermissions(this.ppWorkspaceId);

    if (!timelinePermissions || !tagPermissions.read) {
      this.hasAccessToTimeline = false;
    }

    if (!this.hasAccessToTimeline) {
      this.timelineVisible = false;
    } else {
      this.timelineVisible = timelinePermissions;
    }

    this.commentsVisible = commentPermissions.read;

    if (!this.commentsVisible) {
      this.comments = null;
    }
  }

  private initActivities(): void {
    if (this.timelineVisible) {
      const filters = this.siteFilterDataService.getFilters();
      const keywordFoundInActivities = filters?.pointsWithKeyword?.includes(this.ppPointId);

      if (keywordFoundInActivities) {
        this.showCommentsOnly();
      } else {
        this.fetchActivities();
      }
    } else if (this.commentsVisible) {
      this.showCommentsOnly();
    }
  }

  private startActivities(): void {
    const filters = this.siteFilterDataService.getFilters();

    this.comments = this.pointActivityCommentsService.getComments();

    if (this.timelineVisible) {
      this.fetchActivities();
    }

    if (filters?.pointsWithKeyword?.includes(this.ppPointId) || this.commentsOnly) {
      this.showCommentsOnly();
    }
  }
}
