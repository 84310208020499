import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';

import { TAllCustomFields, TCustomField, TCustomFieldList } from '../custom-fields.model';

import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { CustomFieldsService, ECustomFieldsEventType } from '../custom-fields.service';

import { cloneDeep } from 'lodash';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { getIsBasicFieldName } from '../add-custom-field-modal/utils/get-is-basic-field-name';
import { ECustomFieldType } from '../custom-field-types-enums';
import { getIsContainsSlash } from '../custom-fields-service.utils';
import { customFieldTypesData, TCustomFieldTypeData } from '../custom-fields.data';
import { TEditCustomFieldModalData } from './edit-custom-field-modal.model';

@Component({
  selector: 'app-edit-custom-field-modal',
  templateUrl: './edit-custom-field-modal.component.html',
  styleUrls: [
    './edit-custom-field-modal.component.scss',
    '../add-custom-field-modal/add-custom-field-modal.component.scss',
  ],
})
export class EditCustomFieldModalComponent implements OnInit, OnDestroy {
  modal: Modal<TEditCustomFieldModalData> = this.modalService.getModal();
  customFields: TAllCustomFields = this.customFieldsService.getCustomFields();
  previousName: string;
  workspaceId: string;

  customField: TCustomFieldTypeData = null;
  editedCustomField: TCustomField;

  customFieldTypesData = customFieldTypesData;
  customFieldTypes = ECustomFieldType;

  processing = false;
  listCFError = false;
  focused = false;

  sorted: {
    sorted: boolean;
  } = {
    sorted: false,
  };

  private readonly destroy$ = new Subject<void>();

  constructor(
    private customFieldsService: CustomFieldsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit() {
    this.workspaceId = this.modal.data.workspaceId;
    this.editedCustomField = cloneDeep(this.modal.data.customField);

    this.previousName = this.editedCustomField.label;
    this.customField = this.customFieldTypesData.find(
      (cf) => cf.value === this.editedCustomField.type,
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  save(): void {
    if (this.listCFError) {
      const promptText = this.translationPipe.transform(
        'prompt_invalid_character_slash_custom_field',
      );

      this.promptService.showWarning(promptText);

      return;
    }

    if (this.processing) {
      return;
    }

    const workspaces = this.workspaceService.getWorkspaces();

    const index = workspaces[this.workspaceId].customFields.findIndex(
      (fieldId) =>
        this.customFields[this.workspaceId][fieldId].label.toLowerCase() ===
        this.editedCustomField.label.toLowerCase(),
    );

    const basicFieldName = getIsBasicFieldName(this.editedCustomField.label);
    const emptyListLabel = false;

    this.editedCustomField.label = this.editedCustomField.label.trim();

    if (this.editedCustomField.type === ECustomFieldType.LIST) {
      const containsEmptyListLabel = this.checkEmptyListLabel(this.editedCustomField);
      const containsSlash = this.checkSlash(this.editedCustomField.subList);

      if (containsEmptyListLabel || containsSlash) {
        this.processing = false;

        return;
      }
    }

    if (
      (this.editedCustomField.type === ECustomFieldType.NUMBERS ||
        this.editedCustomField.type === ECustomFieldType.PERCENTAGE) &&
      !this.editedCustomField.decimalPlaces
    ) {
      this.editedCustomField.decimalPlaces = 0;
    }

    if (
      (index === -1 ||
        this.customFields[this.workspaceId][workspaces[this.workspaceId].customFields[index]].id ===
          this.editedCustomField.id) &&
      !basicFieldName &&
      !emptyListLabel
    ) {
      this.processing = true;

      this.updateCustomField()
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.processing = false;
          }),
        )
        .subscribe();
    } else {
      const promptText = this.translationPipe.transform('prompt_new_custom_field_taken');

      this.promptService.showWarning(promptText);
    }
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  updateListError(error: boolean): void {
    this.listCFError = error;
  }

  private updateCustomField(): Observable<TCustomField> {
    const customFieldDTO: Partial<TCustomField> = {
      label: this.editedCustomField.label,
      id: this.editedCustomField.id,
      subList: this.editedCustomField.subList,
      currencyCode: this.editedCustomField.currencyCode,
      type: this.editedCustomField.type,
      unit: this.editedCustomField.unit,
      decimalPlaces: this.editedCustomField.decimalPlaces,
      showTotal: this.editedCustomField.showTotal,
      showCommas: this.editedCustomField.showCommas,
      showHoursOnly: this.editedCustomField.showHoursOnly,
    };

    return this.customFieldsService.updateCustomField(this.workspaceId, customFieldDTO).pipe(
      tap(() => {
        const promptText = this.translationPipe.transform('prompt_field_updated');

        this.customFieldsService.updateWorkspaceCustomField(
          this.workspaceId,
          this.editedCustomField,
        );

        logEventInGTAG(EGoogleEventSettings.SETTINGS__CF__EDIT, {
          event_category: EGoogleEventCategory.SETTINGS,
        });

        this.promptService.showSuccess(promptText);
        this.modalService.hideModal(false);
      }),
      catchError(() => {
        const promptText = this.translationPipe.transform('prompt_field_updated_error');

        this.promptService.showError(promptText);

        return of(null);
      }),
    );
  }

  private checkSlash(customFieldSubList: TCustomFieldList[] | undefined): boolean {
    const slashFound = getIsContainsSlash(customFieldSubList);

    if (slashFound) {
      const promptText = this.translationPipe.transform(
        'prompt_invalid_character_slash_custom_field',
      );

      this.promptService.showWarning(promptText);
    }

    return slashFound;
  }

  private checkEmptyListLabel(customField: TCustomField): boolean {
    const hasEmptyLabel = JSON.stringify(customField).includes('"label":""');

    if (hasEmptyLabel) {
      const promptText = this.translationPipe.transform('prompt_fill_all_warning');

      this.promptService.showWarning(promptText);
      this.customFieldsService.emit(ECustomFieldsEventType.SAVE_CUSTOM_FIELD_CLICKED);
    }

    return hasEmptyLabel;
  }
}
