import { TPointCustomField } from '@project/view-models';

export function getNumbersExportValue(exportType: string, customField: TPointCustomField): string {
  switch (exportType) {
    case 'xlsx':
      return customField.value;
    default:
      return customField.unit ? `${customField.unit} ${customField.value}` : customField.value;
  }
}
