import { formatCurrency } from 'src/app/core/helpers/format-currency';
import { decodeBase64Text } from 'src/app/project/components/input/rich-text/decode-base64-text';
import { generatePlainText } from 'src/app/project/components/input/rich-text/generate-plain-text';
import { transformTimeCF } from 'src/app/project/modules/custom-fields/custom-field-time/custom-field-time-transformer';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';

export function checkCostForKeyword(customField: TPointCustomField, keyword: string): boolean {
  let formattedValue = '';

  if (customField.value) {
    const costCurrencySymbol = customField.currencySymbol;
    const costValue = parseFloat(customField.value);
    formattedValue = formatCurrency(costValue, costCurrencySymbol, +customField.decimalPlaces);
  }

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkNumbersForKeyword(customField: TPointCustomField, keyword: string): boolean {
  const unit = customField.unit ? customField.unit : '';
  const formattedValue = customField?.value ? customField.value + ' ' + unit : '';

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkPercentageForKeyword(
  customField: TPointCustomField,
  keyword: string,
): boolean {
  const formattedValue = customField?.value ? customField.value + ' %' : '';

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkDateForKeyword(customField: TPointCustomField, keyword: string): boolean {
  let formattedValue = '';
  const preferences = GET_PREFERENCES();

  if (customField.value) {
    formattedValue = transformDate({
      value: customField.value,
      inputHourFormat: '',
      format: preferences.dateFormat,
      localTime: false,
    });
  }

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkTimelineForKeyword(customField: TPointCustomField, keyword: string): boolean {
  const preferences = GET_PREFERENCES();
  let formattedValue = '';

  if (customField.value) {
    const values = customField.value.split('~');

    formattedValue =
      transformDate({
        value: values[0],
        inputHourFormat: '',
        format: preferences.dateFormat,
        localTime: false,
      }) +
      ' - ' +
      transformDate({
        value: values[1],
        inputHourFormat: '',
        format: preferences.dateFormat,
        localTime: false,
      });
  }

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkRichTextForKeyword(customField: TPointCustomField, keyword: string): boolean {
  const plainText = generatePlainText(decodeBase64Text(customField.value));

  return plainText.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkTimeForKeyword(customField: TPointCustomField, keyword: string): boolean {
  let formattedValue = '';

  if (customField.value) {
    formattedValue = transformTimeCF(customField.value, customField.showHoursOnly);
  }

  return formattedValue && formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkTextForKeyword(
  pointCustomField: TPointCustomField,
  _keyword: string,
): boolean {
  return (
    pointCustomField.value &&
    pointCustomField.value.toLowerCase().indexOf(_keyword.toLowerCase()) > -1
  );
}
