import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TCustomFieldFilter } from '../site-filter.model';

export function filterListCustomField(
  filterField: TCustomFieldFilter,
  pointCustomField: TPointCustomField,
  customFields: TAllCustomFields,
): boolean {
  if (
    filterField.appliedValues.idOfChosenElement &&
    filterField.appliedValues.path &&
    filterField.appliedValues.text &&
    customFields[filterField.workspaceId][filterField.id].label === pointCustomField.label &&
    pointCustomField.value &&
    pointCustomField.value.startsWith(filterField.appliedValues.path)
  ) {
    return true;
  }

  return !(
    filterField.appliedValues.idOfChosenElement &&
    filterField.appliedValues.path &&
    filterField.appliedValues.text
  );
}
