import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAuthorizationProgressStep } from 'src/app/project/components/authorization-progress/authorization-progress.consts';

@Component({
  selector: 'pp-site-settings-integrations-tooltips',
  templateUrl: './site-settings-integrations-tooltips.component.html',
  styleUrls: ['./site-settings-integrations-tooltips.component.scss'],
})
export class SiteSettingsIntegrationsTooltipsComponent {
  @Input() ppIntegrationStep: EAuthorizationProgressStep;
  @Input() ppDisabled: boolean;
  @Output() ppCancel = new EventEmitter<void>();
  @Output() ppDeactivate = new EventEmitter<void>();
  @Input() ppAssetName: string;
  @Input() ppSiteName: string;

  EAuthorizationProgressStep = EAuthorizationProgressStep;

  cancelConnection(): void {
    this.ppCancel.emit();
  }

  deactivateConnection(): void {
    this.ppDeactivate.emit();
  }
}
