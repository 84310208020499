import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { EAuthorizationProgressStep } from 'src/app/project/components/authorization-progress/authorization-progress.consts';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { getDateFromNow } from 'src/app/project/features/dayjs/get-date-from-now';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EVolyIntegrationStatus } from 'src/app/project/modules/integrations/utils/integrations.consts';
import { VolyIntegrationService } from 'src/app/project/modules/integrations/voly-integration.service';
import { UpdateWorkspaceIntegrations } from 'src/app/project/modules/workspace/workspace.actions';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ConfirmDisconnectIntegrationModalComponent } from './confirm-disconnect-integration-modal/confirm-disconnect-integration-modal.component';
import { TConfirmDisconnectIntegrationModalData } from './confirm-disconnect-integration-modal/confirm-disconnect-integration-modal.consts';

@Component({
  selector: 'pp-site-settings-integrations-authorize-connection',
  templateUrl: './site-settings-integrations-authorize-connection.component.html',
  styleUrls: ['./site-settings-integrations-authorize-connection.component.scss'],
})
export class SiteSettingsIntegrationsAuthorizeConnectionComponent implements OnInit {
  @Input() ppDisabled: boolean;
  @Input() ppIntegrationStep: EAuthorizationProgressStep;
  @Input() ppWorkspaceId: string;
  @Output() ppIntegrationStepChange = new EventEmitter<EAuthorizationProgressStep>();

  headerText: string;
  subheaderText: string;
  EAuthorizationProgressStep = EAuthorizationProgressStep;
  remainingTime: string;
  timeExpired: boolean = false;
  siteName: string;
  assetName: string;

  constructor(
    private translationPipe: TranslationPipe,
    private modalService: ModalService,
    private volyIntegrationService: VolyIntegrationService,
    private workspaceService: WorkspaceService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.setDisplayData();
  }

  ngOnChanges(): void {
    this.setRemainingTime();
  }

  private setRemainingTime(): void {
    const workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);

    if (workspace?.integrations?.voly) {
      if (workspace.integrations.voly.pendingUntil - Date.now() < 0) {
        this.timeExpired = true;
        this.remainingTime = null;
        return;
      }
      this.remainingTime = getDateFromNow(workspace.integrations.voly.pendingUntil);
    } else {
      this.remainingTime = null;
    }
  }

  showAuthorizeConnectionModal(): void {
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('confirm_authorization_description'),
      secondaryMessage: this.translationPipe.transform('confirm_authorization_description_2'),
      heading: this.translationPipe.transform('confirm_authorization'),
      redButton: false,
      confirmText: this.translationPipe.transform('continue'),
      boldText: true,
      boldSecondaryText: true,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: true,
      callback: () => {
        this.authorizeConnection();
      },
    });
  }

  showDeactivateConnectionModal(): void {
    const modalData: TConfirmDisconnectIntegrationModalData = {
      assetName: this.assetName,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmDisconnectIntegrationModalComponent, {
      blur: true,
      callback: () => {
        this.deactivateConnection();
      },
    });
  }

  private authorizeConnection(): void {
    this.volyIntegrationService
      .changeIntegrationStatus(this.ppWorkspaceId, EVolyIntegrationStatus.PENDING)
      .pipe(
        tap((response) => {
          this.store.dispatch(
            new UpdateWorkspaceIntegrations({
              workspaceId: this.ppWorkspaceId,
              integrations: response.integrations,
            }),
          );

          this.ppIntegrationStep = EAuthorizationProgressStep.PENDING;
          this.ppIntegrationStepChange.emit(this.ppIntegrationStep);
          this.setDisplayData();
        }),
      )
      .subscribe();
  }

  cancelConnection(): void {
    this.volyIntegrationService
      .changeIntegrationStatus(this.ppWorkspaceId, EVolyIntegrationStatus.OFF)
      .pipe(
        tap((response) => {
          this.store.dispatch(
            new UpdateWorkspaceIntegrations({
              workspaceId: this.ppWorkspaceId,
              integrations: response.integrations,
            }),
          );

          this.ppIntegrationStep = EAuthorizationProgressStep.INITIAL;
          this.ppIntegrationStepChange.emit(this.ppIntegrationStep);
          this.setDisplayData();
        }),
      )
      .subscribe();
  }

  private deactivateConnection(): void {
    this.volyIntegrationService
      .changeIntegrationStatus(this.ppWorkspaceId, EVolyIntegrationStatus.OFF)
      .pipe(
        tap((response) => {
          this.store.dispatch(
            new UpdateWorkspaceIntegrations({
              workspaceId: this.ppWorkspaceId,
              integrations: response.integrations,
            }),
          );

          this.ppIntegrationStep = EAuthorizationProgressStep.INITIAL;
          this.ppIntegrationStepChange.emit(this.ppIntegrationStep);
          this.setDisplayData();
        }),
      )
      .subscribe();
  }

  private setDisplayData(): void {
    switch (this.ppIntegrationStep) {
      case EAuthorizationProgressStep.INITIAL:
        this.headerText = this.translationPipe.transform('site_is_not_authorized');
        this.subheaderText = '';
        this.siteName = '';
        this.assetName = '';
        break;

      case EAuthorizationProgressStep.PENDING:
        this.headerText = this.translationPipe.transform('site_authorized_for_voly');
        this.subheaderText = this.translationPipe.transform('site_authorized_for_voly_subheader');
        this.siteName = '';
        this.assetName = '';
        break;

      case EAuthorizationProgressStep.ACTIVE: {
        const workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);

        this.siteName = workspace?.siteName;
        this.assetName = workspace?.integrations?.voly?.assetName;
        this.headerText = this.translationPipe.transform('site_activated_for_voly_header');
        this.subheaderText = this.translationPipe.transform('site_activated_for_voly_subheader');
        break;
      }
    }
  }
}
