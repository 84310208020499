import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from '../../../custom-field-types-enums';
import { humanizedTypeNames } from '../../../custom-fields.data';
import { EIntegrationStatus, TCustomField } from '../../../custom-fields.model';
import { GET_CUSTOM_FIELDS } from '../../../custom-fields.store';
import { TSelectedCustomFields } from '../../add-custom-field.model';

@Component({
  selector: 'pp-add-custom-field-modal-import-field',
  templateUrl: './add-custom-field-modal-import-field.component.html',
  styleUrl: './add-custom-field-modal-import-field.component.scss',
})
export class AddCustomFieldModalImportFieldComponent {
  @Input() ppCustomFieldId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppAccountId: string;
  @Input() ppTimelineEnabled: boolean;
  @Input() ppTimelineExists: boolean;
  @Input() ppSelectedCustomFields: TSelectedCustomFields;
  @Input() ppInitialWorkspaceCustomFields: string[];
  @Input() ppSelectedCustomFieldsNames: {
    [fieldName: string]: string;
  } = {};
  @Output() ppSelectField = new EventEmitter<string>();

  customField: TCustomField;
  customFieldTypes = ECustomFieldType;
  humanizedTypeNames = humanizedTypeNames;

  fieldEnabled: boolean;
  tooltipText: string;
  fieldNameAlreadySelected: boolean;

  constructor(private translationPipe: TranslationPipe) {}

  ngOnChanges() {
    this.customField = GET_CUSTOM_FIELDS()[this.ppCustomFieldId];

    this.checkIfFieldAlreadySelected();
    this.checkIfFieldEnabled();
    this.setTooltipText();
  }

  selectCustomField(fieldId: string): void {
    this.ppSelectField.emit(fieldId);
  }

  private checkIfFieldAlreadySelected(): void {
    this.fieldNameAlreadySelected =
      this.ppSelectedCustomFieldsNames[this.customField.label] &&
      this.ppSelectedCustomFieldsNames[this.customField.label] !== this.ppWorkspaceId;
  }

  private checkIfFieldEnabled(): void {
    const fieldTimelineIssue =
      this.customField.type === this.customFieldTypes.TIMELINE &&
      (this.ppTimelineExists || !this.ppTimelineEnabled);

    const fieldAlreadySelected =
      this.ppSelectedCustomFieldsNames[this.customField.label] || this.fieldNameAlreadySelected;

    this.fieldEnabled =
      this.ppWorkspaceId &&
      !this.ppInitialWorkspaceCustomFields.includes(this.customField.label) &&
      !fieldAlreadySelected &&
      !fieldTimelineIssue &&
      this.customField.volyIntegrationActive === EIntegrationStatus.OFF;
  }

  private setTooltipText(): void {
    if (this.fieldNameAlreadySelected) {
      this.tooltipText = this.translationPipe.transform('custom_field_with_name_already_selected');
    } else if (this.customField.type === ECustomFieldType.TIMELINE && this.ppTimelineExists) {
      this.tooltipText = this.translationPipe.transform(
        'prompt_field_create_error_timeline_doubled',
      );
    } else if (this.customField.type === ECustomFieldType.TIMELINE && !this.ppTimelineEnabled) {
      this.tooltipText = this.translationPipe.transform('timeline_not_enabled');
    } else if (this.customField.volyIntegrationActive !== EIntegrationStatus.OFF) {
      this.tooltipText = this.translationPipe.transform('tooltip_import_custom_field_integration');
    } else {
      this.tooltipText = this.translationPipe.transform('custom_field_already_exists_on_site');
    }
  }
}
